import React from 'react';
const FullscreenIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
    fill="currentColor"
  >
    {/* Copy the content of your fullscreen.svg here */}
    <g id="fullscreen-icon">
        <g>
            <path d="M8.3,5H1.7C0.7,5,0,5.7,0,6.7v6.7C0,14.3,0.7,15,1.7,15c0.9,0,1.7-0.7,1.7-1.7v-5h5c0.9,0,1.7-0.7,1.7-1.7
                S9.3,5,8.3,5z"/>
            <path d="M40,13.3V6.7C40,5.7,39.3,5,38.3,5h-6.7C30.7,5,30,5.7,30,6.7c0,0.9,0.7,1.7,1.7,1.7h5v5
                c0,0.9,0.7,1.7,1.7,1.7S40,14.3,40,13.3z"/>
            <path d="M31.7,35h6.7c0.9,0,1.7-0.7,1.7-1.7v-6.7c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7v5h-5
                c-0.9,0-1.7,0.7-1.7,1.7S30.7,35,31.7,35z"/>
            <path d="M0,26.7v6.7C0,34.3,0.7,35,1.7,35h6.7c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7h-5v-5
                c0-0.9-0.7-1.7-1.7-1.7S0,25.7,0,26.7z"/>
        </g>
    </g>
  </svg>
);

export default FullscreenIcon;