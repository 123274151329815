import { useState, useCallback, useEffect } from "react";
import { DefaultPassword } from '../assets/js/constants';
import { usePagination } from "@table-library/react-table-library/pagination";
import {
  Table,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";

const NameList = ({names}) => {
  const LIMIT = 20;
  const DEFAULT_PASSWORD = DefaultPassword;
  const [specialWinners, setSpecialWinner] = useState(localStorage.getItem("saved_specialWinners") ? JSON.parse(localStorage.getItem("saved_specialWinners")) : []);

  const [data, setData] = useState({
    nodes: [],
  });
  const [search, setSearch] = useState("");
  const [password, setPassword] = useState("");
  const [filter, setFilter] = useState(false);

  const doGet = useCallback(async (params) => {
    
    let allNames = names.normalize("NFKC").split("\n").filter((item) =>
      item.toLowerCase().includes(search.toLowerCase())
    )

    if (params.showSpecialOnly) allNames = specialWinners;

    allNames = allNames.map((x) => {
      if (specialWinners.includes(x)) x = "*" + x;
      
      return x;
    });


    let totalPages = Math.ceil(allNames.length / LIMIT);

    setData({
      nodes: allNames.slice(params.offset, params.offset + LIMIT),
      pageInfo: { totalPages: totalPages }
    });
  }, [names, search, specialWinners]);

  useEffect(() => {
    doGet({
      offset: 0,
      limit: LIMIT,
      showSpecialOnly: filter
    });
  }, [doGet,filter]);

  const onPaginationChange = (action, state) => {
    doGet({
      offset: (state?.page ?? 0) * LIMIT,
      limit: LIMIT,
      showSpecialOnly: filter
    });
  }
  
  const pagination = usePagination(
    data,
    {
      state: {
        page: 0,
        size: LIMIT,
      },
      onChange: onPaginationChange,
    },
    {
      isServer: true,
    }
  );

  const handleSearch = (event) => {
    onPaginationChange(null, {
      state: {
        page: 0,
        size: LIMIT,
      },
    })
    setSearch(event.target.value);
  };

  const clickName = (e, item, i) => {
    if (e.detail === 2) {
      let spWinner = specialWinners;
      if (item.substring(0, 1) === "*") {
        data.nodes[i] = item.slice(1);
        spWinner = spWinner.filter(e => e !== item.slice(1));
        setSpecialWinner(spWinner);
      } else {
        data.nodes[i] = "*" + item;
        spWinner.push(item)
        setSpecialWinner(spWinner);
      }

      localStorage.setItem('saved_specialWinners', JSON.stringify(spWinner));
      setData({...data});
      setPage(1);
    }
  };

  const clearWinners = () => {
    setSpecialWinner([]);
    localStorage.setItem('saved_specialWinners', JSON.stringify([]));
  };

  const handleChange = (e) => {
    setFilter(e.target.checked);
    setPage(1);
    doGet({
      offset: 0,
      limit: LIMIT,
      showSpecialOnly: e.target.checked
    });
  };

  const setPage = (e) => {
    let p = parseInt(e ? e : 1);
    pagination.fns.onSetPage(p-1)
    pagination.state.page = p-1;
  };

  const getPageRange = (index) => {
    let current_page = (pagination.state.page+1);
    let mod = (current_page-1) % 10;

    return  current_page - (mod) + index;
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }
  

  
  return (
    <div>
    {
    password === DEFAULT_PASSWORD ?
      <div className="slot">
        <div className="slot__outer modal">
          <div className="slot__inner modal">
            <h2>List of Names</h2>
            <small>(Double click name to select special winner)</small>
            <div className="name-list-table">
              <label className="search" htmlFor="search">
                Search Name:&nbsp;
                <input id="search" type="text" value={search} onChange={handleSearch} />
              </label>
              {
                  data.nodes.length === 0 
                  ? <p>No rows found.</p>
                  :
                    <div className="table-body">
                      <Table data={data} pagination={pagination}>
                        {(tableList) => (
                          <>
                            <Body>
                              {
                                tableList.map((item,i) => (
                                  <Row item={item} key={i}>
                                    <Cell onClick={(event) => clickName(event, item, i)}>{item}</Cell>
                                  </Row>
                                ))
                              }
                            </Body>
                          </>
                        )}
                      </Table>
                    </div>
              }
              {data.pageInfo && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    textAlign: "left"
                  }}
                >
                  <span>
                    Total Pages: {data.pageInfo.totalPages} <br/>
                    Current Page: <input type="number" value={pagination.state.page + 1} onChange={(e) => setPage(e.target.value)} style={{width: '4rem'}} min="1" max={data.pageInfo.totalPages}></input>
                  </span>

                  <span>
                    Page:{data.nodes.length === 0 ? ' No pages' : " "}
                   
                    {
                      pagination.state.page !== 0 ? 
                      <span>
                        <button type="button" onClick={() => pagination.fns.onSetPage(0)}>
                          <span>&#x3c;&#x3c;</span>
                        </button>
                        <button type="button" onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}>
                          <span>&#x3c;</span>
                        </button>
                      </span>
                      : ''
                    }

                    {
                      Array(data.pageInfo.totalPages < 10 ? data.pageInfo.totalPages : 10)
                      .fill()
                      .map((_, index) => 
                        {
                          let page =  getPageRange(index);
                          return (page > data.pageInfo.totalPages ? '' :
                          (<button
                            key={index}
                            type="button"
                            style={{
                              fontWeight:
                                pagination.state.page + 1 === page ? "bold" : "normal",
                            }}
                            onClick={(e) => 
                              setPage(e.target.innerText)
                            }
                          >
                            {page}
                          </button>))
                        }
                      )
                    }
                    {
                      (pagination.state.page + 1) === data.pageInfo.totalPages ? 
                      '' :
                      <span>
                        <button type="button" onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}>
                          <span>&#x3e;</span>
                        </button>
                        <button type="button" onClick={() => pagination.fns.onSetPage(data.pageInfo.totalPages - 1)}>
                          <span>&#x3e;&#x3e;</span>
                        </button>
                      </span>
                    }
                  </span>
                </div>
              )}

              {
                specialWinners.length > 0 ?
                <div style={{float: 'right', marginTop: '10px'}}>
                  <small>Count: {specialWinners.length}</small> 
                  &nbsp;
                  <small>
                    <input type="checkbox"onChange={(e) => handleChange(e)}/>
                    Filter
                  </small>
                  &nbsp;
                  <button type="button" onClick={() => clearWinners()}>
                      Clear Special Winners
                  </button>
                  <br/>
                  <small style={{float: 'right'}}><i>Auto-saved</i></small>
                </div>
                : ''
              }
              
            </div>
          </div>
        </div>
      </div>
      : 
      <div className="slot">
        <div className="slot__outer" style={{width: 'max-content', textAlign: "center", overflow: 'hidden'}}>
          <div className="slot__inner">
            <h2>Enter password to continue:</h2>
            <input type="password" value={password} onChange={(e) => handlePasswordChange(e)}></input>      
            <br/>
            { password ? <small className="danger">Incorrect Password</small> : '' }
          </div>  
        </div>    
      </div>
    }
    </div>
  )
}

export default NameList
