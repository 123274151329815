import { useEffect } from "react";
import { Tooltip } from 'react-tooltip'

const WinnerSetting = ({
    fontSize,
    setFontSize,
    customTitle,
    setCustomTitle,
    noOfWinners,
    setNoOfWinners,
    secsPerDraw,
    setSecsPerDraw,
    setIsRemoveName, 
    isRemoveName, 
    setEnableSoundFx, 
    enableSoundFx, 
    isNameReturned, 
    setIsNameReturned, 
    nameList, 
    setNameList,
    mainColor,
    setMainColor,
    accentColor,
    setAccentColor,
    setAllColors,
    openCloseModal,
    setMessage
  }) => {

  // const settings = storedSettings ? JSON.parse(storedSettings) : null
  
  const getChroma = (e, type) => {
    let mc = (type === 'main') ? e.target.value : mainColor;
    let ac = (type === 'accent') ? e.target.value : accentColor;
    setMainColor(mc);
    setAccentColor(ac);
    setAllColors(mc, ac);
  }

  useEffect(() => {
    setAllColors(mainColor, accentColor);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const changeInput = (e, total) => {
    if (e.target.id === 'noOfWinners' && total > 0 && total <= 30) {
      setNoOfWinners(total);
      // passValues({noOfWinners: total, secsPerDraw: secsPerDraw});
    } else if (e.target.id === 'secsPerDraw' && total > 0 && total <= 20) {
      setSecsPerDraw(total);
      // passValues({noOfWinners: noOfWinners, secsPerDraw: total});
    } else if (e.target.id === 'fontSize' && total > 0 && total <= 8) {
      setFontSize(total);
      // passValues({noOfWinners: noOfWinners, secsPerDraw: total});
    }  else return;
  };

  const getRemovedNames = () => {
    let allRemovedNames = localStorage.getItem('saved_allRemovedNames') ? JSON.parse(localStorage.getItem('saved_allRemovedNames')) :  [];
    return allRemovedNames;
  }

  const moveBackToList = () => {
    if (!isNameReturned) {
      let allRemovedNames = getRemovedNames();
      let newNameList = nameList+"\n".concat(allRemovedNames.join("\n"));
      setNameList(newNameList);
      setIsNameReturned(true);
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve,reject) => {
       const reader = new FileReader();
       reader.onload = () => resolve(reader.result);
       reader.onerror = error => reject(error);
       reader.readAsDataURL(file);
    });
  }

  const handleUploadSession = (e) => {
    const file = e.target.files[0];
    if (file?.size > 5000000) {
      e.target.value = '';
      openCloseModal(true, 'error');
      setMessage('Uploaded file exceeds the limit of 5mb. Please upload a smaller file.')
      return;
    }
    if (file)
      getBase64(file).then(base64 => {
        localStorage["fileBackgroundImage"] = base64;
        let root = document.documentElement;
        root.style.setProperty('--backgroundImage', `url(${base64})`);
      });
  }

  const clearImage = () => {
    document.getElementById('btn-chooseImage').value = null;
    localStorage.removeItem("fileBackgroundImage");
    let root = document.documentElement;
    root.style.setProperty('--backgroundImage', null);
    
  }

  useEffect(() => {
    if (1 <= fontSize && fontSize <= 8 ) {
      document.getElementById('custom-title').className = '';
      document.getElementById('custom-title').classList.add(`size-${fontSize}`);
    }
  }, [fontSize])
  

  return (
    <div>
      <div className="input-group input-group--2-column">
        <label className="input-label" htmlFor="customTitle">Custom Title</label>
        <input type="text" id="customTitle" name="customTitle" className="round-input-number" value={customTitle} style={{width: '20rem'}} onChange={(e)=>setCustomTitle(e.target.value)}/>
      </div>
      <div className="input-group input-group--2-column">
        <label className="input-label" htmlFor="customTitle">Font Size</label>
        <input type="number" id="fontSize" name="fontSize" className="round-input-number"  min="1" max="8" value={fontSize}  onWheel={(e) => e.target.blur()}  onChange={(e) => changeInput(e, e.target.value ? parseInt(e.target.value) : 1 )} />
      </div>
      <div className="input-group input-group--2-column">
        <label className="input-label" htmlFor="remove-from-list">Skin</label>
        <div>
          <select className="round-input-select" onChange={(e) => getChroma(e, 'main')} value={mainColor}> 
            <option value="red">Red</option>
            <option value="pink">Pink</option>
            <option value="purple">Purple</option>
            <option value="deepPurple">Deep Purple</option>
            <option value="indigo">Indigo</option>
            <option value="blue">Blue</option>
            <option value="lightBlue">Light Blue</option>
            <option value="cyan">Cyan</option>
            <option value="teal">Teal</option>
            <option value="green">Green</option>
            <option value="lightGreen">Light Green</option>
            <option value="lime">Lime</option>
            <option value="yellow">Yellow</option>
            <option value="amber">Amber</option>
            <option value="orange">Orange</option>
            <option value="deepOrange">Deep Orange</option>
            <option value="brown">Brown</option>
            <option value="grey">Grey</option>
            <option value="blueGrey">Blue Grey</option> 
          </select>
          <select className="round-input-select" onChange={(e) => getChroma(e, 'accent')} value={accentColor}> 
            <option value="red">Red</option>
            <option value="pink">Pink</option>
            <option value="purple">Purple</option>
            <option value="deepPurple">Deep Purple</option>
            <option value="indigo">Indigo</option>
            <option value="blue">Blue</option>
            <option value="lightBlue">Light Blue</option>
            <option value="cyan">Cyan</option>
            <option value="teal">Teal</option>
            <option value="green">Green</option>
            <option value="lightGreen">Light Green</option>
            <option value="lime">Lime</option>
            <option value="yellow">Yellow</option>
            <option value="amber">Amber</option>
            <option value="orange">Orange</option>
            <option value="deepOrange">Deep Orange</option>
            <option value="brown">Brown</option>
            <option value="grey">Grey</option>
            <option value="blueGrey">Blue Grey</option> 
          </select>
        </div>
      </div>
      <div className="input-group input-group--2-column" style={{position: 'relative'}}>
        <label className="input-label" htmlFor="remove-from-list">Background</label>
        <input id="btn-chooseImage" type="file" accept="image/*" onChange={(e) => handleUploadSession(e)} />
        <small className="anchor" style={{float: 'right', position: 'absolute', cursor: 'pointer', top: '1rem', fontSize: 'small',right: '0px'}} onClick={() => clearImage()}>
          <Tooltip anchorSelect=".anchor" place="top" style={{zIndex: 999}}>
            Clear Background Image
          </Tooltip>
          x
          </small>
      </div>
      <div className="input-group input-group--2-column">
        <label className="input-label" htmlFor="enable-sound">Enable sound effect</label>
        <label className="input--switch">
          <input type="checkbox" id="enable-sound" checked={enableSoundFx} onChange={() => setEnableSoundFx(!enableSoundFx)}/>
          <span className="slider"></span>
        </label>
      </div>
      <div className="input-group input-group--2-column">
        <label className="input-label" htmlFor="noOfWinners">Number of winners</label>
        <input type="number" id="noOfWinners" name="noOfWinners" className="round-input-number"  min="1" max="30" value={noOfWinners}  onWheel={(e) => e.target.blur()}  onChange={(e) => changeInput(e, e.target.value ? parseInt(e.target.value) : 1 )} />
      </div>
      <div className="input-group input-group--2-column">
        <label className="input-label" htmlFor="secsPerDraw">Seconds per draw</label>
        <input type="number" id="secsPerDraw" name="secsPerDraw" className="round-input-number" min="1" max="20" value={secsPerDraw}  onWheel={(e) => e.target.blur()}  onChange={(e) => changeInput(e, e.target.value ? parseInt(e.target.value) : 1 )} />
      </div>
      <div className="input-group input-group--2-column" style={{marginBottom: 0}}>
        <label className="input-label" htmlFor="remove-from-list">Remove winner from list</label>
        <label className="input--switch">
          <input type="checkbox" id="remove-from-list" checked={isRemoveName === true} onChange={() => { setIsRemoveName(!isRemoveName); }}/>
          <span className="slider"></span>
        </label>
      </div>
      {
        getRemovedNames().length > 0 ? 
          <div style={{marginBottom: '1rem'}}>
            <small className="name-list">
              <b>Removed names:</b> {getRemovedNames().length.toLocaleString()} {isNameReturned ? <i>- Unsaved</i> : ''}
              <small className="name-list view" onClick={() => moveBackToList()}>(Return to List)</small>
            </small> 
            
          </div>
        : ''
      }
    
    </div>
  )
}

export default WinnerSetting
