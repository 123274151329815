import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import ThemeColor from 'theme-color';
// import Title from '../assets/images/title.svg';
import Sunburst from '../assets/images/sunburst.svg';
import confetti from 'canvas-confetti';
import Slot from '../assets/js/Slot';
import SoundEffects from '../assets/js/SoundEffects';
import WinnerSetting from './WinnerSetting';
import AllWinners from './AllWinners';
import ToolbarButtons from './ToolbarButtons';
import TitleIcon from '../assets/jsx/TitleIcon';
import Multislot from './Multislot';
import NameList from './NameList';
import Modal from 'react-modal';
Modal.setAppElement('#root');

const Landing = () => {
  const confettiCanvas = useRef(null);
  const customConfetti = useRef(null);
  let confettiAnimationId = useRef(null);

  let nameListFromStorage = localStorage.getItem('saved_nameList') ?? '';
  let specialWinners = localStorage.getItem("saved_specialWinners") ? JSON.parse(localStorage.getItem("saved_specialWinners")): [];
  const storedSettings = localStorage.getItem('settings');
  const settings = storedSettings ? JSON.parse(storedSettings) : null

  let [mainColor, setMainColor] = useState(settings?.colors?.mainColor ?? 'deepPurple');
  let [accentColor, setAccentColor] = useState(settings?.colors?.accentColor ?? 'red');
  let [colors, setColors] = useState(settings?.colors?.colors ?? {
    "lightPrimary": "#ffffff",
    "primary": "#7e3efd",
    "darkPrimary": "#4800A0",
    "accent": "#ff0c45"
  });

  const setAllColors = useCallback((mc, ac) => {
    const tc = new ThemeColor(mc, ac);
    let colors = tc.theme;
    setColors(colors);
    let root = document.documentElement
    root.style.setProperty('--primary', colors.primary);
    root.style.setProperty('--darkPrimary', colors.darkPrimary);
    root.style.setProperty('--accent', colors.accent);
    root.style.setProperty('--lightPrimary', colors.lightPrimary);
    root.style.setProperty('--backgroundImage', `url(${localStorage['fileBackgroundImage']})` ?? '');
  }, [setColors]);

  let [customTitle, setCustomTitle] = useState(settings?.customTitle ?? 'Lucky Draw');
  let [fontSize, setFontSize] = useState(settings?.fontSize ?? 5);
  let [noOfWinners, setNoOfWinners] = useState(settings?.noOfWinners ?? 1);
  // eslint-disable-next-line no-unused-vars
  let [secsPerDraw, setSecsPerDraw] = useState(settings?.secsPerDraw ?? 1);
  let tempWinners =new Array(noOfWinners).fill(null).map((u) => '');
  let [winners, setWinners] = useState(tempWinners);

  const settingsWrapper = useRef(null);
  const settingsPanel = useRef(null);
  const sunburstSvg =  useRef(null);
  const [nameList, setNameList] = useState(nameListFromStorage);
  const [isNameReturned, setIsNameReturned] = useState(false);
  const [isRemoveName, setIsRemoveName] = useState(settings?.isRemoveName ?? true);
  const [enableSoundFx, setEnableSoundFx] = useState(settings?.enableSoundFx ?? true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  //const [includeWinnersInNextDraw, setIncludeWinnersInNextDraw] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalView, setModalView] = useState('');
  const [message, setMessage] = useState('');
  
  // const settingsContent = document.getElementById('settings-panel');
  
  const soundEffects = useMemo(() => new SoundEffects(), []);
  // const MAX_REEL_ITEMS = 50;
  const MAX_SPECIAL_WINNERS = 3;
  const CONFETTI_COLORS = useMemo(() => ['#26ccff', '#a25afd', '#ff5e7e', '#88ff5a', '#fcff42', '#ffa62d', '#ff36ff'], []);

  /** Modal */
  const customStyles = {
    content: {
      background: 'transparent',
      border: 'none',
      zIndex: '999',
      margin:'0 auto',
      display: 'table',
      position: 'absolute',
      left: 0,
      right: 0,
    }
  };
  
  const openCloseModal = (isOpen, view) => {
    setIsOpen(isOpen);
    if (isOpen) setModalView(view);
  };

  /** Confeetti animation instance */
  customConfetti.current = confetti.create(confettiCanvas.current, {
    resize: true,
    useWorker: true
  });
  
  /** Triggers cconfeetti animation until animation is canceled */
  const confettiAnimation = useCallback((runConfettiAnimation) => {
    if (runConfettiAnimation) {
      const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
      const confettiScale = Math.max(0.5, Math.min(1, windowWidth / 1100));
  
      customConfetti.current({
        particleCount: 1,
        gravity: 0.8,
        spread: 120,
        origin: { y: 0.6 },
        colors: [CONFETTI_COLORS[Math.floor(Math.random() * CONFETTI_COLORS.length)]],
        scalar: confettiScale
      });
  
      confettiAnimationId.current = window.requestAnimationFrame(confettiAnimation);
    } else {
      window.cancelAnimationFrame(confettiAnimationId.current);
      return;
    }
  }, [confettiAnimationId, customConfetti, CONFETTI_COLORS]);

  /** Function to stop the winning animation */
  const stopWinningAnimation = useCallback(()=>{
    confettiAnimation(false);
    sunburstSvg.current.style.display = 'none';
  },[confettiAnimation]);

  /**  Function to be trigger before spinning */
  const onSpinStart = async () => {
    
    const settings = JSON.parse(localStorage.getItem('settings') ?? "{}");
    stopWinningAnimation();
    soundEffects.mute = !settings.enableSoundFx;
    setIsButtonDisabled(true);
    const secs = noOfWinners > 1 ? (settings.secsPerDraw ?? 1) + 2 : (((10*settings.secsPerDraw) - 2) / 10);
    console.log("spin", secs);
    await soundEffects.spin(secs);
    console.log("spin end")

    onSpinEnd();
  };

  /**  Functions to be trigger after spinning */
  const onSpinEnd = async () => {
    // if (noOfWinners > 1) stopAnimation.current();

    confettiAnimation(true);
    sunburstSvg.current.style.display = 'block';
    await soundEffects.win();
    setIsButtonDisabled(false);
  };

  const [slot, setSlotInstance] = useState({
    reelContainerSelector: '#reel',
    maxReelItems: noOfWinners > 1 ? 50 : 10 * secsPerDraw,
    maxSpecialWinners: MAX_SPECIAL_WINNERS,
    onSpinStart,
    onSpinEnd,
    onNameListChanged: stopWinningAnimation,
    names: (nameListFromStorage) ? nameListFromStorage.split("\n") : [],
    noOfWinners: noOfWinners,
    removeWinner: isRemoveName,
    specialWinners: specialWinners,
    secsPerDraw: secsPerDraw,
    colors: colors,
    mainColor: mainColor,
    accentColor: accentColor,
    enableSoundFx: enableSoundFx
  });

  const resetState = useCallback(() => {
    // const storedSettings = localStorage.getItem('settings');
    // const settings = storedSettings ? JSON.parse(storedSettings) : null;
    // if (settings) {
    //   slot.removeWinner = settings.isRemoveName;
    //   slot.noOfWinners = settings.noOfWinners;
    //   soundEffects.mute = !settings.enableSoundFx;
    // }
    setNoOfWinners(slot.noOfWinners);
    setSecsPerDraw(slot.secsPerDraw);
    setNameList(slot.names.length ? slot.names.join('\n') : '');
    setIsRemoveName(slot.removeWinner);
    setEnableSoundFx(slot.enableSoundFx);
    setIsNameReturned(false);
    setColors(slot.colors);
    setMainColor(slot.mainColor);
    setAccentColor(slot.accentColor);
    setAllColors(slot.mainColor, slot.accentColor);
    document.getElementById('btn-chooseImage').value= null;
  }, [setAllColors, slot]);
  
  /** To open the setting page */
  const onSettingsOpen = useCallback(()=>{
    stopWinningAnimation();
    if (nameListFromStorage) slot.names = nameListFromStorage ? nameListFromStorage.split("\n") : [];
    settingsWrapper.current.style.display = 'block';
    
    resetState();
  
  },[nameListFromStorage, resetState, slot, stopWinningAnimation]); 

  /** To close the setting page */
  const onSettingsClose = useCallback(()=>{
    stopWinningAnimation();
    settingsWrapper.current.style.display = 'none';
    resetState();
  },[resetState, stopWinningAnimation]);

  // Click handler for "Draw" button
  const drawBtnClick = async () => {
    if (!slot.names.length || slot.names.length < noOfWinners) {
      setMessage('Please input names or check number of winners.');
      openCloseModal(true, 'error');
      onSettingsOpen();
      return;
    } else {
      resetState();
    }
    
    // onSpinStart();

    let specialWinners = localStorage.getItem("saved_specialWinners") ? JSON.parse(localStorage.getItem("saved_specialWinners")): [];
    stopWinningAnimation();

    const slotMachine = new Slot(slot);
    slotMachine.names = slot.names;
    slotMachine.specialWinners = specialWinners;
    let {winners, newNameList, newSpecialWinnerList} = await slotMachine.spin();
    setWinners(winners);

    let allWinners = localStorage.getItem(`saved_allWinners`) ? JSON.parse(localStorage.getItem(`saved_allWinners`)) : [];
    allWinners.push({winners: winners, dateDrawn: new Date()});
    localStorage.setItem(`saved_allWinners`, JSON.stringify(allWinners));

    if (isRemoveName) {
      //remove winners from special winner list
      localStorage.setItem(`saved_specialWinners`, JSON.stringify(newSpecialWinnerList));


      let allRemovedNames = localStorage.getItem(`saved_allRemovedNames`) ? JSON.parse(localStorage.getItem(`saved_allRemovedNames`)) : [];
      allRemovedNames = allRemovedNames.concat(winners);
      localStorage.setItem(`saved_allRemovedNames`, JSON.stringify(allRemovedNames));

      setSlotInstance({...slot, names: newNameList});
      setNameList(newNameList.join("\n"));
      localStorage.setItem("saved_nameList", newNameList.join("\n"));
    }
  }

  const getNames = () => {
    let names = nameList
    ? nameList.normalize("NFKC").split(/\n/).filter((name) => Boolean(name.trim()))
    : [];

    return names;
  };
  
  // Click handler for "Save" button for setting page
  const settingsSaveBtnClick = () => {
    stopWinningAnimation();
    onSettingsClose();

    setWinners(new Array(noOfWinners).fill(null).map((u) => ''));
    let names = getNames();

    setAllColors(mainColor, accentColor);
    setEnableSoundFx(enableSoundFx);

    setSlotInstance((prev) => { return {
      ...prev, 
      names: names, 
      removeWinner:isRemoveName,
      noOfWinners: noOfWinners,
      secsPerDraw: secsPerDraw,
      colors: colors,
      mainColor: mainColor,
      accentColor: accentColor,
      enableSoundFx: enableSoundFx,
      maxReelItems: 10 * secsPerDraw
    }});
    setNameList(names.join("\n"));
    soundEffects.mute = !enableSoundFx;
    let arrNames = removeDuplicates(names);
    if (!customTitle) setCustomTitle('Lucky Draw');

    localStorage.setItem('settings', JSON.stringify({enableSoundFx, noOfWinners, isRemoveName, secsPerDraw: secsPerDraw ?? 1, customTitle, fontSize, colors: {mainColor: mainColor, accentColor: accentColor, colors: colors}}));
    localStorage.setItem("saved_nameList", arrNames.join("\n"));

    if (isNameReturned) {
      localStorage.setItem('saved_allRemovedNames','');
    }

    // window.location.reload();
  };

  const handleChangeNameList = (e) => {
    const updatedNameList = e.target.value;
    setNameList(updatedNameList);

    const removedNames = winners.filter((winner) => !updatedNameList.includes(winner));
    if (removedNames.length > 0) {
      setIsRemoveName(true);
    }
  };

  const removeDuplicates = (names) => {
    //remove duplicates
    let arrNames = [...new Set(names)];
    return arrNames;
  };

  const resetSettings = () => {
    localStorage.clear();
    setCustomTitle('Lucky Draw');
    setFontSize(5);
    setMainColor('deepPurple');
    setAccentColor('red');
    setAllColors('deepPurple', 'red');
    setEnableSoundFx(true);
    setNoOfWinners(1);
    setSecsPerDraw(1);
    setIsRemoveName(true);
    setNameList('');
    setSlotInstance({
      ...slot,
      names: [],
      specialWinners: [],
      removeWinner:true,
      noOfWinners: 1,
      secsPerDraw: 1,
      colors: colors,
      mainColor: 'deepPurple',
      accentColor: 'red',
      enableSoundFx: true
    });    
    document.getElementById('btn-chooseImage').value = ''
    // onSettingsClose();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const isClickInsideSettings = settingsWrapper.current && settingsWrapper.current.contains(event.target);
      const isClickOutsidePanel = settingsPanel.current && !settingsPanel.current.contains(event.target);
  
      if (isClickInsideSettings && isClickOutsidePanel) {
        onSettingsClose();
      }
    };
  
    const addOutsideClickListener = () => {
      document.addEventListener('click', handleOutsideClick);
    };
  
    const removeOutsideClickListener = () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  
    addOutsideClickListener();
  
    return () => {
      removeOutsideClickListener();
    };
  }, [onSettingsClose,onSettingsOpen, settingsWrapper, settingsPanel]);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => openCloseModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
        closeTimeoutMS={500}
      >
      {
        modalView === 'winners' 
        ? <AllWinners/> 
        : ''
      }
      {
        modalView === 'namelist' 
        ? <NameList names={nameList}/>
        : ''
      }
      {
        modalView === 'error' 
        ? 
          <div className="slot" style={{backgroundColor: 'white', padding: '1rem', textAlign: 'center'}}>
            <span>{message}</span>
            <br/>
            <small>(Click outside to exit)</small>
          </div>
        : ''
      }
      </Modal>
      <div className="main">
        <div className="control-group">
           <ToolbarButtons onSettingsOpen={onSettingsOpen} isButtonDisabled={isButtonDisabled} openCloseModal={openCloseModal}/>
        </div>
        <canvas className="confetti" id="confetti-canvas" ref={confettiCanvas}></canvas>
        <div id="lucky-draw">
            <div>
              {/* <img src={Title} alt="Lucky Draw" /> */}
              {/* <TitleIcon colors={colors}/> */}
              <span id="custom-title">{customTitle}</span>
            </div>
            {
              slot.noOfWinners > 1 
              ? 
              <Multislot winners={winners} secsPerDraw={secsPerDraw}  disabled={isButtonDisabled}/>
              :
              <div className="slot" title={winners[0]}>
                <div className="slot__outer">
                    <div className="slot__inner">
                        <div className="slot__shadow"></div>
                        <div className="reel" id="reel">
                          <div className="pulsate">Let's select a winner!</div>
                        </div>
                    </div>
                </div>
              </div>
            }
             <div className="sunburst" id="sunburst" ref={sunburstSvg}><img src={Sunburst} alt="sunburst" /></div>
            <button className="solid-button" id="draw-button" onClick={drawBtnClick} disabled={isButtonDisabled}> DRAW </button>
        </div>
        <div className="settings" id="settings" ref={settingsWrapper}>
          <div className="settings__panel" id="settings-panel" ref={settingsPanel}>
              <div className="settings__panel__group">
                  <h1 className="settings__title">Settings</h1>
                  <small className="name-list view" onClick={() => openCloseModal(true, 'namelist')}>Table View</small>
                  <div className="input-group" style={{marginBottom: 0}}>
                    <label className="input-label name-list-header" 
                    htmlFor="name-list">List of names</label>
                    <textarea className="input-field input-field--textarea" rows="15" placeholder="Separate each name by line break" id="name-list"  autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={handleChangeNameList} value={nameList}></textarea>
                  </div>
                  <div  style={{marginBottom: '1rem', textAlign: 'center'}}>
                    <small className="name-list"><b>Total count of names:</b> {getNames().length.toLocaleString()}</small>
                  </div>
                  <WinnerSetting 
                    fontSize={fontSize}
                    setFontSize={setFontSize}
                    customTitle={customTitle}
                    setCustomTitle={setCustomTitle}
                    setIsRemoveName={setIsRemoveName} 
                    isRemoveName={isRemoveName} 
                    setEnableSoundFx={setEnableSoundFx}
                    enableSoundFx={enableSoundFx}
                    isNameReturned={isNameReturned}
                    setIsNameReturned={setIsNameReturned}
                    nameList={nameList}
                    setNameList={setNameList}
                    mainColor={mainColor}
                    setMainColor={setMainColor}
                    accentColor={accentColor}
                    setAccentColor={setAccentColor}
                    colors={colors}
                    setColors={setColors}
                    noOfWinners={noOfWinners}
                    setNoOfWinners={setNoOfWinners}
                    secsPerDraw={secsPerDraw}
                    setSecsPerDraw={setSecsPerDraw}
                    setAllColors={setAllColors}
                    openCloseModal={openCloseModal}
                    setMessage={setMessage}
                  />
              </div>
              <div className="settings__panel__group">
                <button className="solid-button" id="settings-save" onClick={settingsSaveBtnClick}>Save</button>
                <button className="solid-button solid-button--danger" id="settings-close" onClick={resetSettings}>Reset Settings</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
