
import moment from"moment";
import { CompactTable } from '@table-library/react-table-library/compact';

const AllWinners = () => {
 
  const getNames = () => {
    let winners = localStorage.getItem(`saved_allWinners`) ? JSON.parse(localStorage.getItem(`saved_allWinners`)) : [];
    winners = winners.sort((a,b) => new Date(b.dateDrawn) - new Date(a.dateDrawn));
    return winners;
  };
  const COLUMNS = [
    { label: '', renderCell: (item) => item },
  ];

  return (
    <div>
      <div className="slot">
        <div className="slot__outer modal">
          <div className="slot__inner modal">
            <h2 style={{marginBottom: '1rem'}}>Lucky Winners</h2>
            { getNames().length === 0 && <h1>No Winners Yet</h1> }
            {
              getNames().map((_el, j) => {
                const nodes = _el.winners;
                const data = {nodes};
                return (
                  
                  <div key={`draw_${j}`} >
                    <span className='won'>Winners on {moment(_el.dateDrawn).format("MM/DD/YYYY hh:mm A")} Draw</span>
                    <div className='win-list'>
                      <CompactTable columns={COLUMNS} data={data}/>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllWinners
