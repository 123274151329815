import SlotCounter from 'react-slot-counter';
import { Tooltip } from 'react-tooltip'

const Multislot = ({winners, secsPerDraw, disabled}) => {
  const sliceWinners = () => {
    const arrLen = Math.ceil(winners.length/10);
    const slicedWinners = [];

    for (let i = 0; i < arrLen; i++) {
      slicedWinners.push(winners.slice(i*10, 10*(i+1)));
    }

    return slicedWinners;
  };

  return (
    <div>
      <div className="slot">
        <div className="slot__outer multislot">
          <div className="slot__inner multislot">
            <div className="slot__list modal">
            { 
                sliceWinners().map((wnners,i) => {
                  return ( 
                    <ol key={"winners_" + i}>
                      {
                        wnners.map((el,j) => { 
                          return (
                          <li key={(10*i + (j + 1))} className={'anchor' +(10*i + (j + 1))}>
                            {!disabled && <Tooltip anchorSelect={".anchor"+(10*i + (j + 1))} place="top" style={{zIndex: 999}}>
                              {el.toUpperCase()}
                            </Tooltip>}
                            <SlotCounter 
                              value={el ? el.toUpperCase() : 'JUAN DELA CRUZ'} 
                              dummyCharacters={'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')}
                              dummyCharacterCount={26}
                              startValue={'JUAN DELA CRUZ'}
                              duration={secsPerDraw ?? 1}
                              charClassName="slot__char"
                              separatorClassName="slot__separator"
                              containerClassName="slot__container"
                              direction={'bottom-top'}
                              autoAnimationStart={false}
                              hasInfiniteList={true}
                              animateUnchanged
                            >
                            </SlotCounter>
                          </li>
                        ) })
                      }
                    </ol>
                  )
                })
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Multislot
