
import React, { useState } from "react";
import FullscreenIcon from '../assets/jsx/FullscreenIcon';
import SettingsIcon from '../assets/jsx/SettingsIcon';
import WinnersIcon from '../assets/jsx/WinnersIcon';
import { Tooltip } from 'react-tooltip'


const ToolbarButtons = ({isButtonDisabled, onSettingsOpen, openCloseModal}) => {
  let [isFullScreen, setFullscreen] = useState(false);

  // Click handler for "Fullscreen" button
  const fullscreenBtnClick = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      return;
    }
  };

  document.addEventListener("fullscreenchange", () => {
    if (document.fullscreenElement) {
      setFullscreen(true);
    } else {
      setFullscreen(false);
    }
  });


  return (
    <div>

        {!isFullScreen && (
          <button className="icon-button icon-button--small" id="view-winners-button" onClick={() => openCloseModal(true, 'winners')} disabled={isButtonDisabled}>
            <Tooltip anchorSelect="#view-winners-button">View all winners</Tooltip>
            <WinnersIcon className="red big" />
          </button> 
        )}
        {!isFullScreen && (
          <button className="icon-button icon-button--small" id="fullscreen-button" onClick={fullscreenBtnClick} >
            <Tooltip anchorSelect="#fullscreen-button">Fullscreen</Tooltip>
            <FullscreenIcon className="red big" />
          </button> 
        )}
        {!isFullScreen && (
          <button className="icon-button icon-button--small" id="settings-button" onClick={() => {onSettingsOpen();}} disabled={isButtonDisabled}>
            <Tooltip anchorSelect="#settings-button">Settings</Tooltip>
            <SettingsIcon className="red big" />
          </button>
        )}
    </div>
  )
}

export default ToolbarButtons
