import React from 'react';

const SettingsIcon = () => (
    <svg 
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        x="0px" 
        y="0px"
        xmlSpace="preserve"
        viewBox="0 0 40 40" 
        style={{ enableBackground: 'new 0 0 40 40' }}
        fill="currentcolor">
    <g id="setting-icon">
    <path d="M6.7,13.3c3.1,0,5.7-2.1,6.5-5h25.2c0.9,0,1.7-0.7,1.7-1.7C40,5.7,39.3,5,38.3,5H13.1c-0.7-2.9-3.4-5-6.5-5
        C3,0,0,3,0,6.7S3,13.3,6.7,13.3z M6.7,3.3c1.8,0,3.3,1.5,3.3,3.3S8.5,10,6.7,10S3.3,8.5,3.3,6.7S4.8,3.3,6.7,3.3z"/>
    <path d="M33.3,26.7c-3.1,0-5.7,2.1-6.5,5H1.7c-0.9,0-1.7,0.7-1.7,1.7S0.7,35,1.7,35h25.2c0.7,2.9,3.4,5,6.5,5
        c3.7,0,6.7-3,6.7-6.7S37,26.7,33.3,26.7z M33.3,36.7c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3
        C36.7,35.2,35.2,36.7,33.3,36.7z"/>
    <path d="M38.3,18.3H26.5c-0.7-2.9-3.4-5-6.5-5s-5.7,2.1-6.5,5H1.7C0.7,18.3,0,19.1,0,20c0,0.9,0.7,1.7,1.7,1.7h11.9
        c0.7,2.9,3.4,5,6.5,5s5.7-2.1,6.5-5h11.9c0.9,0,1.7-0.7,1.7-1.7S39.3,18.3,38.3,18.3z M20,23.3c-1.8,0-3.3-1.5-3.3-3.3
        s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S21.8,23.3,20,23.3z"/>
    </g>
    </svg>
);

export default SettingsIcon;