interface SlotConfigurations {
  /** User configuration for maximum item inside a reel */
  maxReelItems?: number;
  /** User configuration for maximum special winners */
  maxSpecialWinners?: number;
  /** User configuration for whether winner should be removed from name list */
  removeWinner?: boolean;
  /** User configuration for element selector which reel items should append to */
  reelContainerSelector: string;
  /** User configuration for callback function that runs before spinning reel */
  onSpinStart?: () => void;
  /** User configuration for callback function that runs after spinning reel */
  onSpinEnd?: () => void;

  /** User configuration for callback function that runs after user updates the name list */
  onNameListChanged?: () => void;
  noOfWinners?: number;
  specialWinners?: string[];
}

/** Class for doing random name pick and animation */
export default class Slot {
  /** List of names to draw from */
  private nameList: string[];

  /** Whether there is a previous winner element displayed in reel */
  private havePreviousWinner: boolean;

  /** Container that hold the reel items */
  private reelContainer: HTMLElement | null;

  /** Maximum item inside a reel */
  private maxReelItems: NonNullable<SlotConfigurations['maxReelItems']>;

  /** Number of winners per draw */
  private noOfWinners: NonNullable<SlotConfigurations['noOfWinners']>;

  /** Whether winner should be removed from name list */
  private shouldRemoveWinner: NonNullable<SlotConfigurations['removeWinner']>;

  /** Reel animation object instance */
  private reelAnimation?: Animation;

  /** Callback function that runs before spinning reel */
  private onSpinStart?: NonNullable<SlotConfigurations['onSpinStart']>;

  /** Callback function that runs after spinning reel */
  private onSpinEnd?: NonNullable<SlotConfigurations['onSpinEnd']>;

  /** Callback function that runs after spinning reel */
  private onNameListChanged?: NonNullable<SlotConfigurations['onNameListChanged']>;
  
  /**specialWinners */
  private specialWinners? : NonNullable<SlotConfigurations['specialWinners']>;
  private maxSpecialWinners?: NonNullable<SlotConfigurations['maxSpecialWinners']>;

  /**
   * Constructor of Slot
   * @param maxReelItems  Maximum item inside a reel
   * @param maxSpecialWinners  Maximum number of special winners
   * @param noOfWinners  Number of winners per draw
   * @param specialWinners  Special Winners
   * @param removeWinner  Whether winner should be removed from name list
   * @param reelContainerSelector  The element ID of reel items to be appended
   * @param onSpinStart  Callback function that runs before spinning reel
   * @param onNameListChanged  Callback function that runs when user updates the name list
   */
  constructor(
    {
      maxReelItems = 50,
      maxSpecialWinners = 3,
      noOfWinners = 1,
      specialWinners = [],
      removeWinner = true,
      reelContainerSelector,
      onSpinStart,
      onSpinEnd,
      onNameListChanged
    }: SlotConfigurations
  ) {
    this.nameList = [];
    this.havePreviousWinner = false;
    this.reelContainer = document.querySelector(reelContainerSelector);
    this.maxReelItems = maxReelItems;
    this.noOfWinners = noOfWinners;
    this.shouldRemoveWinner = removeWinner;
    this.onSpinStart = onSpinStart;
    this.onSpinEnd = onSpinEnd;
    this.onNameListChanged = onNameListChanged;
    this.specialWinners = specialWinners;
    this.maxSpecialWinners = maxSpecialWinners;
    
    // Create reel animation
    this.reelAnimation = this.reelContainer?.animate(
      [
        { transform: 'none', filter: 'blur(0)' },
        { filter: 'blur(1px)', offset: 0.5 },
        // Here we transform the reel to move up and stop at the top of last item
        // "(Number of item - 1) * height of reel item" of wheel is the amount of pixel to move up
        // 7.5rem * 16 = 120px, which equals to reel item height
        { transform: `translateY(-${(this.maxReelItems - 1) * (7.5 * 16)}px)`, filter: 'blur(0)' }
      ],
      {
        duration: this.maxReelItems * 100, // 100ms for 1 item
        easing: 'ease-in-out',
        iterations: 1
      }
    );

    this.reelAnimation?.cancel();
  }

  /**
   * Setter for name list
   * @param names  List of names to draw a winner from
   */
  set names(names: string[]) {
    this.nameList = names;

    const reelItemsToRemove = this.reelContainer?.children
      ? Array.from(this.reelContainer.children)
      : [];

    reelItemsToRemove
      .forEach((element) => element.remove());

    this.havePreviousWinner = false;

    if (this.onNameListChanged) {
      this.onNameListChanged();
    }
  }

  /** Getter for name list */
  get names(): string[] {
    return this.nameList;
  }

  /**
   * Setter for shouldRemoveWinner
   * @param removeWinner  Whether the winner should be removed from name list
   */
  set shouldRemoveWinnerFromNameList(removeWinner: boolean) {
    this.shouldRemoveWinner = removeWinner;
  }

  /** Getter for shouldRemoveWinner */
  get shouldRemoveWinnerFromNameList(): boolean {
    return this.shouldRemoveWinner;
  }

  /**
   * Returns a new array where the items are shuffled
   * @template T  Type of items inside the array to be shuffled
   * @param array  The array to be shuffled
   * @returns The shuffled array
   */
  private static shuffleNames<T = unknown>(array: T[]): T[] {
    const keys = Object.keys(array) as unknown[] as number[];
    const result: T[] = [];
    for (let k = 0, n = keys.length; k < array.length && n > 0; k += 1) {
      // eslint-disable-next-line no-bitwise
      const i = Math.random() * n | 0;
      const key = keys[i];
      result.push(array[key]);
      n -= 1;
      const tmp = keys[n];
      keys[n] = key;
      keys[i] = tmp;
    }
    return result;
  }

  /**
   * Function for spinning the slot
   * @returns Whether the spin is completed successfully
   */
  public async spin(): Promise<any> {
    if (!this.nameList.length) {
      console.error('Name List is empty. Cannot start spinning.');
      return false;
    }


    if (this.onSpinStart) {
      this.onSpinStart();
    }

    const { reelContainer, reelAnimation, shouldRemoveWinner, noOfWinners, specialWinners } = this;
    const maxSpecialWinners =  this.maxSpecialWinners ?? 3;
    let selectedSpecialWinners: string[] = [];
    if (noOfWinners === 1 && (!reelContainer || !reelAnimation)) {
      return false;
    }
    
    let specialWinnerNames = specialWinners ?? [];
   
    // Shuffle names and create reel items
    let randomNames = Slot.shuffleNames<string>(this.nameList);

    //fill up if less than max reel items
    while (randomNames.length && randomNames.length < this.maxReelItems) {
      randomNames = [...randomNames, ...randomNames];
    }

    randomNames = randomNames.slice(0, this.maxReelItems - Number(this.havePreviousWinner));
    
    
    // let theWinner = randomNames[randomNames.length - 1];
    let theWinners = randomNames.slice(-noOfWinners);
    
    //rigging begins here
    if (specialWinnerNames.length > 0) {
      theWinners = randomNames.filter((x) => !specialWinnerNames.includes(x)).slice(-noOfWinners);
      let max = maxSpecialWinners;
      if (specialWinnerNames.length < 3) max = specialWinnerNames.length;
      if (noOfWinners === 1) {
        theWinners = specialWinnerNames.slice(-noOfWinners);
        randomNames = randomNames.slice(0, -1).concat(theWinners);
      } else {
        if (max >= noOfWinners) {
          let newMaxWinners = Math.floor(noOfWinners / 2);
          selectedSpecialWinners = specialWinnerNames.slice(-newMaxWinners);
          theWinners = theWinners.slice(newMaxWinners).concat(selectedSpecialWinners);
        } else {
          selectedSpecialWinners = specialWinnerNames.slice(-max);
          theWinners = theWinners.slice(-(noOfWinners-max)).concat(selectedSpecialWinners);
        }
      }
    }

    //creating reel items
    if (noOfWinners === 1 && reelContainer) {
      const fragment = document.createDocumentFragment();

      randomNames.forEach((name) => {
        const newReelItem = document.createElement('div');
        newReelItem.innerHTML = name;
        fragment.appendChild(newReelItem);
      });

      reelContainer.appendChild(fragment);
    }

    //shuffle winners
    theWinners =  Slot.shuffleNames<string>(theWinners);
    
    // console.log('Displayed items: ', randomNames);
    // console.log('Winner: ', theWinners);
    // console.log('Special Winners: ', selectedSpecialWinners);

    // Remove winner form name list if necessary
    if (shouldRemoveWinner) {
      this.nameList = this.nameList.filter( ( el ) => !theWinners.includes( el ) );
      specialWinnerNames = specialWinnerNames.filter( ( el ) => !theWinners.includes( el ) );
    }

    // console.log('Remaining: ', this.nameList);
    if (noOfWinners === 1 && reelAnimation && reelContainer) {
      // Play the spin animation
      // if (noOfWinners > 1) {
      //   Array.from(reelContainer.children).slice(-1)[0].textContent = `${noOfWinners} Winners!`;
      // }

      const animationPromise = new Promise((resolve) => {
        reelAnimation.onfinish = resolve;
      });

      reelAnimation.play();

      await animationPromise;

      // Sets the current playback time to the end of the animation
      // Fix issue for animatin not playing after the initial play on Safari

      reelAnimation.finish();

      Array.from(reelContainer.children)
        .slice(0, reelContainer.children.length - 1)
        .forEach((element) => element.remove());

      this.havePreviousWinner = true;
      //end spin animation

      if (this.onSpinEnd) {
        // this.onSpinEnd();
      }  
    }
    return {winners: theWinners, newNameList: this.nameList, newSpecialWinnerList: specialWinnerNames};
  }
}
