import Landing from './components/Landing';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header theme--purple img--background">
        <Landing />
      </header>
    </div>
  );
}

export default App;
